import moment from "moment";

export const saveToLocalStorage = (dossierKey, value) =>{
  localStorage.setItem(dossierKey, JSON.stringify(value));
  const dispatchOption = {
    key: dossierKey,
    newValue: JSON.stringify(value)
  }
  window.dispatchEvent(new StorageEvent('storage', dispatchOption))
}
export const getFromLocalStorage = (dossierKey) =>
  JSON.parse(localStorage.getItem(dossierKey) || "[]");
export const clearLocalStorage = (dossierKey) =>{
  localStorage.removeItem(dossierKey);
  const dispatchOption = {
    key: dossierKey,
    newValue: null
  }
  window.dispatchEvent(new StorageEvent('storage', dispatchOption))
}

//codes to handle text edit local storage information
export const saveEditsToLocalStorage = (key, value) =>{
  localStorage.setItem(key, JSON.stringify(value));
  const dispatchOption = {
    key,
    newValue: JSON.stringify(value)
  }
  window.dispatchEvent(new StorageEvent('storage', dispatchOption))
}

export const getEditsFromLocalStorage = (key) =>
  JSON.parse(localStorage.getItem(key) || "{}");

export const clearEditsFromLocalStorage = (key) => {
  localStorage.removeItem(key);
  const dispatchOption = {
    key,
    newValue: null
  }
  window.dispatchEvent(new StorageEvent('storage', dispatchOption))}

//below code will handle and save data in storage on paragraph updates
export const saveUpdatedStorageData = (primaryKey, secondryKey, data) => {
  const storageData = getEditsFromLocalStorage(primaryKey);
  const section_data = {};
  section_data[secondryKey] =
    storageData && storageData[secondryKey]
      ? { ...storageData[secondryKey], ...data }
      : data;

  saveEditsToLocalStorage(primaryKey, {
    ...storageData,
    ...section_data,
  });
};
export const updateTaskReview = (section_data,secondryKey, editedData) => {
  section_data[secondryKey].map((item, index) => {
    if ((item.mpdTaskReference && item.mpdTaskReference === editedData.mpdTaskReference) || (item.ompTaskReference && item.ompTaskReference===editedData.ompTaskReference)) { // Assuming you use mpdTaskReference as the unique identifier
      Object.keys(item).forEach((column)=> {
        if(!['Edit','type','modifiedColumns'].includes(column) && section_data[secondryKey][index][column] !== editedData[column]) {
          section_data[secondryKey][index][column] = editedData[column]
          section_data[secondryKey][index].type = "Modified";
          if(!Object.keys(item).includes("modifiedColumns")) {
            section_data[secondryKey][index]['modifiedColumns'] = new Set([]);
          }
          else {
            section_data[secondryKey][index]['modifiedColumns'] = new Set(JSON.parse(section_data[secondryKey][index]['modifiedColumns']))
          }
          section_data[secondryKey][index]['modifiedColumns'].add(column)
          section_data[secondryKey][index]['modifiedColumns'] = JSON.stringify([...section_data[secondryKey][index]['modifiedColumns']])
        }
      })
    }
  });
}
export const saveUpdatedStorageDataForTable = (
  primaryKey,
  secondryKey,
  data,
  tableData,
  isEdit,
  selectedRow
) => {
  const storageData = getEditsFromLocalStorage(primaryKey);
  const section_data = {
    [secondryKey]: storageData?.[secondryKey] ? [...storageData[secondryKey]] : []
  };

  const columnName = secondryKey.includes("Record_of_Revision") ? "revision" : "historyNumber";

  if (isEdit && Object.keys(selectedRow).length) {
    const hasExistingData = section_data[secondryKey].length > 0;

    if (secondryKey.includes("Task_Review") || hasExistingData) {
      if (secondryKey.includes("Task_Review")) {
        section_data[secondryKey] = hasExistingData ? section_data[secondryKey] : [...tableData];
        updateTaskReview(section_data, secondryKey, data);
      } else {
        section_data[secondryKey].forEach(item => {
          if (item[columnName] === selectedRow[columnName]) {
            updateData(section_data, secondryKey, columnName, selectedRow, data, "type" in item);
          }
        });
      }
    } else {
      section_data[secondryKey] = [...tableData];
      updateData(section_data, secondryKey, columnName, selectedRow, data, false);
    }
  } else {
    const newRow = formatNewRow(data, secondryKey, tableData, columnName);
    section_data[secondryKey] = [
      ...(storageData?.[secondryKey] || tableData),
      newRow
    ];
  }
  saveEditsToLocalStorage(primaryKey, { ...storageData, ...section_data });
};

export const updateData = (
  section_data,
  secondryKey,
  columnName,
  selectedRow,
  data,
  isAlreadyModified
) => {
  section_data[secondryKey].map((item, index) => {
    if (item[columnName] === selectedRow[columnName]) {
      section_data[secondryKey][index].date = data.Date
        ? moment(data.Date).format("DD-MMM-YYYY")
        : selectedRow.date;
      section_data[secondryKey][index].content =
        data["Change Description"] || data["Reason for Change"] || selectedRow.content
      section_data[secondryKey][index][columnName] = parseInt(selectedRow[columnName])
     if (!isAlreadyModified) {
      section_data[secondryKey][index].type = "Modified";
      }
    }
  });
  return section_data;
};
export const formatNewRow = (obj, title, tableData, columnName) => {
  const newdataArr = tableData;
  const formattedObj = {
    content: obj["Change Description"] || obj["Reason for Change"],
    date: obj.Date && moment(obj.Date).format("DD-MMM-YYYY"),
    type: "Created",
  };

  const maxRevision =
    newdataArr &&
    newdataArr.map((elements) => {
      return parseInt(elements[columnName]);
    });
  const maxRevisionNum = maxRevision.length > 0 ? Math.max(...maxRevision) : -1;
  formattedObj[columnName] = maxRevisionNum + 1;

  return formattedObj;
};

export const resetSavedDatafromLS = (currHeading, primaryKey, secondryKey) => {
  const storageData = getEditsFromLocalStorage(primaryKey);
  const sectionData =
    Object.keys(storageData).length !== 0 && storageData[secondryKey];
  if (Object.keys(sectionData).includes(currHeading)) {
    delete sectionData[currHeading];
    if (Object.keys(sectionData).length !== 0) {
      storageData[secondryKey] = sectionData;
      saveEditsToLocalStorage(primaryKey, storageData);
    } else {
      delete storageData[secondryKey];
      saveEditsToLocalStorage(primaryKey, storageData);
    }
  }
  Object.keys(storageData).length === 0 &&
    clearEditsFromLocalStorage(primaryKey);
};

export const getLocalStorageKeys = () => Object.keys(localStorage);
/**
 * return the updated formatted with disabled columns data information
 * @param {Object} formattedSaveData
 * @param {Object} disabledColumnsList
 * @returns
 */
export const handleDisabledCols = (formattedSaveData, disabledColumnsList) => {
  Object.keys(disabledColumnsList).forEach((key) => {
    const [section, filter] = key.split("@");
    const section_text = section_mapper[section];
    if (formattedSaveData[section_text]) {
      const filterData = formattedSaveData[section_text][filter];
      if (filterData) {
        formattedSaveData[section_text][filter] = {
          ...filterData,
          ...disabledColumnsList[key],
        };
      } else {
        formattedSaveData[section_text][filter] = {
          ...disabledColumnsList[key],
        };
      }
    } else {
      const filterObj = {};
      filterObj[filter] = { ...disabledColumnsList[key] };
      formattedSaveData[section_text] = { ...filterObj };
    }
  });
  return formattedSaveData;
};

//code to formulate the unique section key as per mrb or mpd filters
export const getSectionKey = (sources, label, dossierFilters, customCurrFilter = "") => {
  const { cardinality, mrbTask } = dossierFilters
    ? dossierFilters
    : { cardinality: null, mrbTask: null };
  const filter =
    sources[0] && sources[0].value.filters
      ? sources[0].value.filters[0]
      : false;
  const curr_filter = customCurrFilter
      ? customCurrFilter
      : cardinality === "1_1"
      ? `mrbr_${mrbTask}`
      : filter
      ? filter.split(" ")[2]
      : "dossier";

  return `${label.split(" ").join("_")}@${curr_filter}`;
};

//code to check if there is edited text data in local storage for the report and section
export const checkDataInStorage = (report_key, section_key) => {
  const localTextEditData = getEditsFromLocalStorage(report_key);
  const isDataAvailable = localTextEditData && localTextEditData[section_key];
  return {
    isDataAvailable,
    editedDataHeadings: isDataAvailable
      ? Object.keys(localTextEditData[section_key])
      : [],
  };
};

//Delete the RoR and TH row from Localstorage on click of delete
export const deleteFormTable = (
  primaryKey,
  secondryKey,
  selectedRow,
  columnName,
  unfilteredData
) => {
  const storageData = getEditsFromLocalStorage(primaryKey);
  const section_data = {};
  section_data[`deleted_${secondryKey}`] =
    storageData && storageData[`deleted_${secondryKey}`]
      ? [...storageData[`deleted_${secondryKey}`]]
      : [];
  section_data[secondryKey] = storageData &&
    storageData[secondryKey] && [...storageData[secondryKey]];
  if (section_data[secondryKey] && section_data[secondryKey].length > 0) {
    const updatedData = section_data[secondryKey].filter((item) => {
      if (
        item[columnName] === selectedRow[columnName] &&
        item.type !== "Created"
      ) {
        section_data[`deleted_${secondryKey}`].push(item[columnName]);
      }
      return item[columnName] !== selectedRow[columnName];
    });
    section_data[secondryKey] = [...updatedData];
  } else {
    section_data[`deleted_${secondryKey}`] = [selectedRow[columnName]];
    section_data[secondryKey] = [...unfilteredData];
  }
  saveEditsToLocalStorage(primaryKey, {
    ...storageData,
    ...section_data,
  });
};

const section_mapper = {
  Technical_Evaluation: "com_technical_evaluation",
  Interval_Proposal: "com_interval_proposal",
  Operator_Comments: "com_technical_evaluation",
  Report_Cover: "reportCover",
  Consolidated_Task: "znl_consolidated_task",
  Component_Reliability: "sys_component_reliability"
};

const signature_map = {
  preparedBy: "TA_PREPBY",
  checkedBy: "TA_CHECKBY",
}


//Formatting for mapping column name according to Task History or Revision History Data Set
const sections = ["Record_of_Revision", "Task_History", "Task_Review"];
export const COLUMN_MAP = {
  Task_History: {
    content: "TA_HISTORY_CONTENT",
    date: "TA_HISTORY_DATE",
    versionNumber: "TA_HISTORY_VERSION_NUMBER",
  },
  Record_of_Revision: {
    content: "TA_REVISION_CONTENT",
    date: "TA_REVISION_VERSION_DATE",
    versionNumber: "TA_REVISION_VERSION_NUMBER",
  },
};

const del_sections_mapper = {
  deleted_Record_of_Revision: "com_ad_revision_history_delete",
  deleted_Task_History: "com_ad_task_history_delete",
  Section_Edit: "com_ad_section",
};

const createRoRTHObj = (section_text, item, versionNumber) => {
  const eachObj = {};
  eachObj[`${COLUMN_MAP[section_text].content}`] = item.content;
  eachObj[`${COLUMN_MAP[section_text].date}`] = moment(item.date).format(
    "YYYY-MM-DD"
  );
  eachObj[`${COLUMN_MAP[section_text].versionNumber}`] =
   versionNumber;
  return eachObj;
};
const key_mapper_api = {
  Record_of_Revision_Created: "com_ad_revision_history_create",
  Record_of_Revision_Modified: "com_ad_revision_history_modify",
  Task_History_Created: "com_ad_task_history_create",
  Task_History_Modified: "com_ad_task_history_modify",
};
const taskReviewMapping = {
  source: "Source",
  taskInterval: "Task Interval",
  ompTaskDescription: "Task Description",
  taskDescription: "Task Description",
  applicability:"Applicability",
  maintenanceProgram:"A/C Program",
  ompSampleThreshold:"Sample Threshold",
  ompSampleInterval: "Sample Interval",
  ompThreshold:"100% Threshold",
  omp100Interval:"100% Interval",
  ompTaskTPS:"TPS",
  mpdSampleThreshold:"Sample Threshold",
  mpdSampleInterval: "Sample Interval",
  mpdThreshold:"100% Threshold",
  mpd100Interval:"100% Interval",
  mpdTaskTPS:"TPS",
  accessPreperation:"Access / Preparation"
};
//below code is to create a post req format of data for edited text save api call
export const formatSaveData = (
  storageKey,
  dossierData,
  disabledColumnsList
) => {
  const payload_sections = {
    com_ad_revision_history_create: [],
    com_ad_revision_history_modify: [],
    com_ad_task_history_create: [],
    com_ad_task_history_modify: [],
  };

  const com_ad_section = {}
  const TextEditStorageData = getEditsFromLocalStorage(storageKey);
  const formattedSaveData = Object.keys(TextEditStorageData).reduce(
    (acc, key) => {
      const finalObj = {...acc};
      const [section_text, filter] = key.split("@");
      const filters = ['preparedBy', 'checkedBy']
      //handles Interval_Proposal,Technical_Evaluation and Operator_Comments
      if (section_mapper[section_text] && !filters.includes(filter)) {
        const section = section_mapper[section_text];
        const filterObj = {};
        filterObj[filter] = TextEditStorageData[key];
        finalObj[section] = { ...finalObj[section], ...filterObj };
        acc = { ...acc, ...finalObj };
      } else if(section_text.includes("Task_Review")) {
        const keyChangedArray = TextEditStorageData[key].filter(obj => obj.type).map(item => {
          const modifiedKeys = [...JSON.parse(item.modifiedColumns), 'mpdTaskReference', 'ompTaskReference'];
          return Object.keys(item).reduce((newItem, key) => {
            if (modifiedKeys.includes(key)) {
              newItem[taskReviewMapping[key] || key] = item[key]; // Use the new key or the old key if no mapping exists
            }
            return newItem;
          }, {});
        });
  
        acc.taskReview = keyChangedArray.reduce((reviewAcc, curr) => {
          const { ompTaskReference, mpdTaskReference, ...rest } = curr;
          reviewAcc[mpdTaskReference || ompTaskReference] = rest;
          return reviewAcc;
        }, {});
      }else if (sections.indexOf(section_text) > -1) {
        //handles ROR,TH
        const filterObj = TextEditStorageData[key];
        filterObj.map((item) => {
          if (Object.keys(item).includes("type")) {
            payload_sections[key_mapper_api[`${section_text}_${item.type}`]].push(
                  createRoRTHObj(section_text, item, (section_text.includes("Record_of_Revision")) ? item.revision : item.historyNumber)
                );
              acc = { ...acc, ...payload_sections };
          }
        });
      } else if (del_sections_mapper[section_text]) {
        // handles delete ROR and TH
        acc[`${del_sections_mapper[section_text]}`] = TextEditStorageData[key];
        //section_text === "Section_Edit"
        //handle preparedby and checkby
      } else if(section_mapper[section_text] && filters.includes(filter)){
        const section = `com_ad_section_modify`;
        com_ad_section[`${signature_map[filter]}`]= TextEditStorageData[key]
        const filterObj = {}
        filterObj[section]= {...com_ad_section}
        finalObj[section]= { ...finalObj[section], ...filterObj[section] };
        acc = { ...acc, ...finalObj };
      }

      else /* istanbul ignore if */
      if (key.startsWith('Consolidated_Task') || key.startsWith('Component_Reliability')) {
        acc[key] = TextEditStorageData[key];
      }
      return acc;
    },
    {
      dossierKey: dossierData.dossierKey,
      exerciseKey: dossierData.exerciseNameKey,
      dossierType: dossierData.dossierType,
    }
  );
  return handleDisabledCols(formattedSaveData, disabledColumnsList);
};

export const resetSignature = (report_level_key) => {
  const TextEditStorageData = getEditsFromLocalStorage(report_level_key);
  const keysToCheck = ["Report_Cover@preparedBy", "Report_Cover@checkedBy"]; // List of keys to check

  const SignatureKeysExist = keysToCheck.some(
    (key) => key in TextEditStorageData
  );
  if (!SignatureKeysExist) {
    delete TextEditStorageData["isCreateModify"];
    if (Object.keys(TextEditStorageData).length) {
      saveEditsToLocalStorage(report_level_key, TextEditStorageData);
    } else {
      clearEditsFromLocalStorage(report_level_key);
    }
  }
};
