import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import EditableField from "../EditableField/EditableField";
import Loader from "../../../Loader/Loader";
import airbusLogo from "../../../../assets/resources/images/airbus.svg";
import { getValue, prepareSubtitle} from "../../TitleHelper";
import { apiGetImage } from "../../../../models/imageModel/imageApi";
import "./TitleDataStyle.scss";
import { ROLES, STATUS } from "../../../../config/dossier.status";
import {isFieldEditableByProgram} from "./utils";

const renderAircraftImg = (logo) => {
  return (
    <div style={{ textAlign: "left" }}>
      <img
        src={`${logo.value}`}
        alt={`${logo.name}`}
        style={{
          // width: logo.width || 230,
          width: "auto",
          maxHeight: logo.width || 140,
          marginLeft: 90,
          marginTop : 10,
        }}
      />
    </div>
  );
};

const signatureStyles = {
  editableContainer: {
  editIcon: {
    color: "#fff",
  },
  editableInput: {
    background: "transparent",
    // borderColor: '1px solid #fff',
    borderWidth: "1px",
    borderColor: "#da1884",
    fontSize: "large",
    fontWeight: "400",
    color: "#fff",
  },
  iconBtn: {
    color: "#fff",
    background: "none",
  },
  width: "40vw"
}
};

const TitleData = (props) => {
  const { details, titleDetails } = props;
  const dispatch = useDispatch();
  const { aircraftImage } = useSelector((store) => store.image);
  const aircraftImageName = getValue(titleDetails, details.logo.value);
  const { reportName } = useParams();

  const { userData, dossierStatus, dossierStats } = useSelector((store) => store);
  const EditAccess = ROLES.onlySpecialist.indexOf(userData.user.role) > -1 &&
  STATUS.TECHNICAL_EVALUATION === dossierStatus.status;
  const titleInfo = dossierStats?.dossier?.data?.titleInfo?.[0];
  const maintenanceProgram = titleInfo?.mpdTaskMaintenanceProgram;
  useEffect(() => {
    !aircraftImage && dispatch(apiGetImage(aircraftImageName, "aircraftImage"));
  }, [aircraftImage, aircraftImageName, dispatch]);
  const updatedAircraftImg = { ...details.logo, value: aircraftImage , name : aircraftImageName};
  const updatedSubtitles = details.subtitles
    .filter((subtitle) => subtitle.hidden === "false" || !subtitle.hidden)
    .map((subtitle) => prepareSubtitle(titleDetails, subtitle));
  const updatedSignature = () => {
    return details.signatures.map((signature) => {
      const key = signature.defaultValue.split(".");
      return signature.editable && EditAccess ? (
        <div>
          <span>{signature.label}</span>
          <span>
            <EditableField
              valueToDisplay={getValue(
                titleDetails,
                signature.value,
                signature.defaultValue
              )}
              keyName={key[key.length - 1]}
              reportName={reportName}
              userData={userData}
              idPopText={`validation-success-${key[key.length - 1]}`}
              signature={true}
              customCss={signatureStyles}
            />
          </span>
        </div>
      ) : (
        <div>
          {signature.label +
            " " +
            getValue(titleDetails, signature.value, signature.defaultValue)}
        </div>
      );
    });
  };
  return updatedAircraftImg.value ? (
    <div className="title-data-cls">
      {renderAircraftImg(updatedAircraftImg)}
      <div className="texts">
        <h3 className="title"> {getValue(titleDetails, details.title)} </h3>
        <div className="subtitle-container">
          {updatedSubtitles.map((item, index) => {
            const {keyName} = item;
            if (keyName === 'mpdTask' && maintenanceProgram === 'A220') {
              return null; // "MPD Task(s):" line hide in A220 context
            }
            return item.isEditable && // normal loop
            EditAccess && isFieldEditableByProgram(keyName, maintenanceProgram) ? (
              <EditableField
                key={`${index}-${item.value}-editable-field`}
                valueToDisplay={item.value}
                keyName={item.keyName}
                reportName={reportName}
                userData={userData}
                idPopText={`validation-success-${item.keyName}`}
              />
            ) : (
              <div className="subtitle-item" key={`${index}-${item.value}-subtitle-item`}>{item.value}</div>
            );
          })}
        </div>
      </div>

      <div className="footer-container">
        <div className="small-step"></div>
        <div className="footer">
          <div className="signatures">{updatedSignature()}</div>
          <div
            className="logo"
            style={{ backgroundImage: `url('${airbusLogo}')` }}
          />
        </div>
      </div>
    </div>
  ) : (
    <Loader />
  );
};

TitleData.propTypes = {
  details: PropTypes.object.isRequired,
  titleDetails: PropTypes.object.isRequired,
  reportName: PropTypes.string.isRequired,
};

export default TitleData;
